<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/nanotech_logo.png')"
        contain
        max-width="200"
        min-height="100"
        width="100%"
      />

      <base-title
        size="text-h5"
        space="4"
        title="NANOTECH SOLUTIONS SDN BHD"
        weight="regular"
      />
      <base-title
        class="grey--text"
        size="text-h6"
        space="4"
        title="200401031900 (670408-T)"
        weight="regular"
      />

      <base-body>
        We specialize in providing chemical solutions for your business needs. We provide a wide range of high quality products with consistent supply; ranging from raw materials, industry chemical grade reagents to laboratory grade reagents. Our competitive prices will ensure your competitive edge in the industry. Your work made easier with hassle-free efficient operations, logistics and freight arrangement.
      </base-body>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfoNanotech',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
