<template>
  <v-theme-provider>
    <div>
      <template v-for="({ icon, text, title: t }, i) in leeheng">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :title="!dense ? t : undefined"
          horizontal
          space="0"
          color="primary"
          dark
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <div
            v-html="text"
          />
        </base-avatar-card>
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContactNanotech',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
      countury: String,
    },
    created () {
      this.leeheng[2].text = this.$t('contact.address')

      this.$EventBus.$on('localeChanged', () => {
        this.leeheng[2].text = this.$t('contact.address')
      })
    },
    data: () => ({
      leeheng: [
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+82 070-4038-9634',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'touchfree@leehengglobal.com',
        },
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '',
        },
      ],
    }),
  }
</script>
